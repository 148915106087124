<template>
  <div class="modal-body">
    <div class="modal-body__close" @click="$emit('close')">
      <img src="@/assets/images/icons/x.svg" alt="close">
    </div>

    <h3 class="modal-body__title" v-html="title"></h3>
    <p class="modal-body__description" v-html="description"></p>

    <div class="modal-body__btns">
      <VButton label="Редактировать"
               class="modal-body__btn"
               clickEffect
               link
               :to="`/vacancies/${vacancyId}/edit`"/>

      <VButton label="Отменить"
               class="modal-body__btn"
               bg="#E4E7EE"
               color="#1E3959"
               bgHover="#BBC4CD"
               colorHover="#1E3959"
               @click="$emit('close')"
               clickEffect/>
    </div>
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";

defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
});

const route = useRoute();

const vacancyId = route.params.id;
</script>

<style scoped lang="scss">
.modal-body {
  position: relative;
  padding: 28.5px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    max-width: 230px;
    margin-top: 16px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  &__description {
    max-width: 230px;
    margin-top: 8px;
    text-align: center;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -.5px;
  }

  &__btns {
    width: 100%;
  }

  &__btn {
    width: 100%;
    margin-top: 24px;

    &:first-child {
      &:active {
        background-color: #006894 !important;
        color: #fff !important;
      }
    }

    &:last-child {
      margin-top: 12px;

        &:active {
            color: #1E3959 !important;
            background-color: #8F9CAC !important;
        }
    }
  }
}
</style>

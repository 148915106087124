<template>
  <div class="verification-documents">
    <div class="verification-documents__documents">
      <div v-if="!noTitle" class="verification-documents__title">Запрашиваемые документы</div>

      <div class="verification-documents__documents-list">
        <VDocumentTemplate
            v-for="document in documents"
            :name="document.title"
            :required="document.is_required"
            :id="document.id"
            hideMenu/>
      </div>
    </div>
    <div
        v-if="extendedFormId" class="verification-documents__questionnaire">
      <div class="verification-documents__title">Расширенная анкета</div>

      <div class="verification-documents__questionnaire-content">
        <div class="verification-documents__questionnaire-template">
          <img src="@/assets/images/icons/document-gray.svg" class="verification-documents__questionnaire-template-icon"
               alt="document">

          <div class="verification-documents__questionnaire-template-wrapper">
            <div class="verification-documents__questionnaire-template-title">Анкета массового подбора</div>
            <p class="verification-documents__questionnaire-template-subtitle">Шаблон анкеты</p>
          </div>
        </div>

        <div @click="viewQuestionnaire" class="verification-documents__questionnaire-btn">Посмотреть анкету</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router";
import VDocumentTemplate from "@/components/UI/VDocumentTemplate"

defineProps({
  documents: Array,
  extendedFormId: Number,
  noTitle: {
    type: Boolean,
    default: false
  }
})

const router = useRouter()
const route = useRoute()

const viewQuestionnaire = () => {
  router.push({path: '/extended-questionnaire', query: {id: route.params.id, pageType: 'card'}})
}
</script>

<style scoped lang="scss">
.verification-documents {
  &__documents {
    margin-top: 20px;
    border-top: 1px solid $light-gray-1;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
  }

  &__documents-list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 187px 187px 187px 187px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  &__questionnaire {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $light-gray-1;
  }

  &__questionnaire-content {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  &__questionnaire-template {
    width: 384px;
    height: 56px;
    padding: 0 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: $light;
  }

  &__questionnaire-template-icon {
    width: 24px;
  }

  &__questionnaire-template-wrapper {
    margin-left: 12px;
  }

  &__questionnaire-template-title {
    font-size: 14px;
    font-weight: 400;
  }

  &__questionnaire-template-subtitle {
    margin-top: 4px;
    color: $gray;
    font-size: 12px;
    font-weight: 400;
  }

  &__questionnaire-btn {
    margin-left: 16px;
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
  }
}
</style>

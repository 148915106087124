<template>
    <div class="actions-menu">
        <h3 class="actions-menu__title">Действия по вакансии</h3>

        <div class="actions-menu__btns" v-if="route.meta.type === 'vacancy'">
            <router-link :to="`/vacancies/${vacancyId}/edit`" class="actions-menu__btn">
                <img src="@/assets/images/icons/pencil.svg" class="actions-menu__btn-icon" alt="pencil">
                <p class="actions-menu__btn-label">Редактировать</p>
            </router-link>
<!--            <div class="actions-menu__btn" @click="createTemplate">-->
<!--                <img src="@/assets/images/icons/add-template.svg" class="actions-menu__btn-icon" alt="pencil">-->
<!--                <p class="actions-menu__btn-label">Добавить в шаблоны</p>-->
<!--            </div>-->
            <div class="actions-menu__btn actions-menu__btn_red" v-if="isPublished" @click="showPopupCloseVacancy = true">
                <img src="@/assets/images/icons/close-red.svg" class="actions-menu__btn-icon" alt="pencil">
                <p class="actions-menu__btn-label">Закрыть вакансию</p>
            </div>
            <div class="actions-menu__btn actions-menu__btn_red" v-if="!isPublished" @click="showPopupDeleteDraft = true">
                <img src="@/assets/images/icons/delete-red.svg" class="actions-menu__btn-icon" alt="pencil">
                <p class="actions-menu__btn-label">Удалить черновик</p>
            </div>
        </div>

        <div class="actions-menu__btns" v-if="route.meta.type === 'template'">
            <router-link :to="`/templates/${vacancyId}/edit`" class="actions-menu__btn">
                <img src="@/assets/images/icons/pencil.svg" class="actions-menu__btn-icon" alt="pencil">
                <p class="actions-menu__btn-label">Редактировать</p>
            </router-link>
            <div class="actions-menu__btn actions-menu__btn_red" @click="showPopupDeleteTemplate = true">
                <img src="@/assets/images/icons/delete-red.svg" class="actions-menu__btn-icon" alt="pencil">
                <p class="actions-menu__btn-label">Удалить шаблон</p>
            </div>
            <div class="actions-menu__footer"></div>
            <router-link :to="`/templates/${vacancyId}/route`" class="actions-menu__btn" style="margin-top: 0">
              <img src="@/assets/images/icons/route-btn.svg" class="actions-menu__btn-icon" alt="pencil">
              <p class="actions-menu__btn-label">Маршрут согласования</p>
            </router-link>
        </div>

        <div class="actions-menu__footer" v-if="isPublished">
            <router-link :to="`/vacancies/${vacancyId}/route`" class="actions-menu__btn">
                <img src="@/assets/images/icons/route-btn.svg" class="actions-menu__btn-icon" alt="pencil">
                <p class="actions-menu__btn-label">Маршрут согласования</p>
            </router-link>
            <router-link :to="`/responses/?vacancyId=${vacancyId}`" class="actions-menu__btn">
                <img src="@/assets/images/icons/responses.svg" class="actions-menu__btn-icon" alt="pencil">
                <p class="actions-menu__btn-label">Отклики по вакансии</p>
            </router-link>
        </div>

        <ModalWindow v-if="showPopupDeleteDraft" @close="showPopupDeleteDraft = false" class="actions-menu__modal">
            <DeleteDraft title="черновик" @close="showPopupDeleteDraft = false" @delete="deleteDraft"/>
        </ModalWindow>

        <ModalWindow v-if="showPopupDeleteTemplate" @close="showPopupDeleteTemplate = false" class="actions-menu__modal">
            <DeleteDraft title="шаблон" @close="showPopupDeleteTemplate = false" @delete="deleteDraft"/>
        </ModalWindow>

        <ModalWindow v-if="showPopupCloseVacancy" @close="showPopupCloseVacancy = false" class="actions-menu__modal">
            <CloseVacancy @close="showPopupCloseVacancy = false" :textModal="textModal" @closeVacancy="closeVacancy"/>
        </ModalWindow>

        <ModalWindow v-if="showPopupAddedTemplate" @close="showPopupAddedTemplate = false" class="actions-menu__modal">
            <AddedTemplate @close="showPopupAddedTemplate = false" :speciality="specialityName"/>
        </ModalWindow>
    </div>
</template>

<script setup>
import {ref, onMounted, onActivated} from "vue";
import {useRouter, useRoute} from "vue-router";
import vacancies from "@/api/vacancies";
import DeleteDraft from '@/components/ModalWindow/ModalBodyes/DeleteDraft'
import CloseVacancy from '@/components/ModalWindow/ModalBodyes/CloseVacancy'
import AddedTemplate from '@/components/ModalWindow/ModalBodyes/AddedTemplate'

const route = useRoute();
const router = useRouter();

defineProps({
    isPublished: Boolean,
    specialityName: String
})

const vacancyId = ref(route.params.id);

const showPopupDeleteDraft = ref(false)
const showPopupCloseVacancy = ref(false)
const showPopupAddedTemplate = ref(false)
const showPopupDeleteTemplate = ref(false)

const deleteDraft = async () => {
    try {
        await vacancies.deleteVacancy(vacancyId.value)

        if (route.meta.type === 'vacancy') {
            await router.push('/vacancies')
        }
        if (route.meta.type === 'template') {
            await router.push({path: '/templates', query: {update: true}})
        }
    } catch (error) {
        console.log(error)
    }
}

const textModal = ref('Благодарим за ваш отклик. \n' +
    'Мы внимательно ознакомились с вашим резюме. К сожалению, на данном этапе мы не готовы предложить вам работу. Искренне желаем удачи в поисках работы!')

const closeVacancy = async (rejectionReason) => {
    if (rejectionReason.length) textModal.value = rejectionReason
    try {
        await vacancies.getCloseVacancy(vacancyId.value, {
            rejection_reason: textModal.value
        })

        await router.push({path: '/vacancies', query: {update: true}})
    } catch (error) {
        console.log(error)
    }
}

const createTemplate = async () => {
    try {
        const response = await vacancies.getVacancy(vacancyId.value, {
            action: 'create_template'
        })
        if (response.blocked_by_contract) await router.push('/')
        showPopupAddedTemplate.value = true
    } catch (error) {
        console.log(error)
    }
}

onActivated(() => {
    vacancyId.value = route.params.id
})
</script>

<style scoped lang="scss">
.actions-menu {
    width: 249px;
    padding: 24px 16px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 #0000000A;

    &__title {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
    }

    &__btns {
        margin-top: 16px;
    }

    &__btn {
        width: 100%;
        padding: 0 8px;
        height: 32px;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        transition: .3s;
        cursor: pointer;
        text-decoration: none;
        user-select: none;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &:hover {
            background-color: $light;
        }

        &:active {
            background-color: $light-gray-1;
        }
    }

    &__btn-icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    &__btn-label {
        margin-left: 8px;
        color: $blue;
        font-size: 14px;
        font-weight: 400;
    }

    &__btn_red &__btn-label {
        color: $red;
    }

    &__footer {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid $light-gray-1;
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 477px;
        }
    }
}
</style>
